import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useAuthStore } from '../store/authStore';
import { Task } from '../types';
import { TaskForm } from '../components/tasks/TaskForm';
import { TaskMatrix } from '../components/tasks/TaskMatrix';
import { Button } from '../components/ui/Button';
import { 
  ChevronLeft, 
  ChevronRight,
  Calendar
} from 'lucide-react';
import { TaskStats } from '../components/tasks/TaskStats';
import { TaskAnalytics } from '../components/analytics/TaskAnalytics';

export const Dashboard: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useAuthStore(state => state.user);
  const setUser = useAuthStore(state => state.setUser);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [viewMode, setViewMode] = useState<'day' | 'week' | 'month' | 'all'>('day');

  const fetchTasks = async () => {
    if (!user?.id) return;

    try {
      let query = supabase
        .from('tasks')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (viewMode === 'day') {
        const startOfDay = new Date(selectedDate);
        startOfDay.setUTCHours(0, 0, 0, 0);
        
        const endOfDay = new Date(selectedDate);
        endOfDay.setUTCHours(23, 59, 59, 999);

        query = query
          .gte('created_at', startOfDay.toISOString())
          .lte('created_at', endOfDay.toISOString());
      }

      const { data, error } = await query;

      if (error) throw error;

      const formattedTasks = data?.map(task => ({
        id: task.id,
        user_id: task.user_id,
        user_email: task.user_email,
        user_name: task.user_name,
        task: task.task,
        urgency: task.urgency,
        importance: task.importance,
        time_taken: task.time_taken,
        task_time: task.task_time,
        target_date: task.target_date,
        created_at: task.created_at,
        updated_at: task.updated_at
      })) || [];

      setTasks(formattedTasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchTasks();
    }
  }, [user, selectedDate, viewMode]);

  const handleTaskClick = (task: Task) => {
    console.log('Task clicked:', task);
  };

  const handleTaskUpdated = () => {
    fetchTasks();
  };

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="p-6">
        {/* Welcome Message */}
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900">
            Welcome back, {user?.email?.split('@')[0]}
          </h2>
          <p className="text-gray-600 mt-1">
            Manage your tasks using the Urgent-Important Matrix
          </p>
        </div>

        {/* Task Form */}
        <div className="mb-8">
          <TaskForm onTaskAdded={fetchTasks} />
        </div>

        {/* Date Navigation */}
        <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
            <div className="flex items-center space-x-4">
              <div className="flex items-center bg-gray-50 rounded-lg p-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    const date = new Date(selectedDate);
                    date.setDate(date.getDate() - 1);
                    setSelectedDate(date.toISOString().split('T')[0]);
                  }}
                  className="hover:bg-gray-100 rounded-lg"
                >
                  <ChevronLeft className="h-5 w-5 text-gray-600" />
                </Button>

                <div className="flex items-center px-4">
                  <input
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    className="hidden"
                    id="date-picker"
                  />
                  <label 
                    htmlFor="date-picker" 
                    className="flex items-center cursor-pointer hover:text-blue-600 transition-colors"
                  >
                    <span className="text-sm font-medium mr-2">{formatDate(selectedDate)}</span>
                    <Calendar className="h-4 w-4" />
                  </label>
                </div>

                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    const date = new Date(selectedDate);
                    date.setDate(date.getDate() + 1);
                    setSelectedDate(date.toISOString().split('T')[0]);
                  }}
                  className="hover:bg-gray-100 rounded-lg"
                >
                  <ChevronRight className="h-5 w-5 text-gray-600" />
                </Button>
              </div>
            </div>

            <div className="flex items-center space-x-3">
              <select
                value={viewMode}
                onChange={(e) => setViewMode(e.target.value as any)}
                className="bg-gray-50 border border-gray-200 text-gray-700 text-sm rounded-lg 
                         focus:ring-blue-500 focus:border-blue-500 block p-2.5
                         appearance-none cursor-pointer hover:bg-gray-100 transition-colors"
              >
                <option value="day">Daily View</option>
                <option value="week">Weekly View</option>
                <option value="month">Monthly View</option>
                <option value="all">All Tasks</option>
              </select>
            </div>
          </div>
        </div>

        {/* Task Stats */}
        <div className="mb-8">
          <TaskStats tasks={tasks} />
        </div>

        {/* Task Matrix */}
        {isLoading ? (
          <div className="bg-white rounded-lg p-8">
            <div className="flex flex-col items-center justify-center min-h-[400px]">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
              <p className="mt-4 text-gray-600">Loading your tasks...</p>
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-lg p-6">
            <TaskMatrix 
              tasks={tasks}
              onTaskClick={handleTaskClick} 
              onTaskDeleted={fetchTasks}
              onTaskUpdated={handleTaskUpdated}
            />
          </div>
        )}
      </div>
    </div>
  );
};