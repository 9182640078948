import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from '../../store/authStore';

// List of admin emails - you can move this to an environment variable later
const ADMIN_EMAILS = ['patilketan2021@gmail.com']; // Replace with your admin email

interface AdminGuardProps {
  children: React.ReactNode;
}

export const AdminGuard: React.FC<AdminGuardProps> = ({ children }) => {
  const user = useAuthStore(state => state.user);

  // Check if user is authenticated and is an admin
  const isAdmin = user && ADMIN_EMAILS.includes(user.email || '');

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!isAdmin) {
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
}; 