import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { 
  Clock, 
  Target, 
  Zap, 
  Coffee, 
  ArrowRight, 
  CheckCircle2,
  BarChart2,
  Brain,
  Layout,
  Calendar,
  TrendingUp,
  Users,
  CheckSquare,
  HeartHandshake,
  Plus
} from 'lucide-react';
import { Button } from '../components/ui/Button';

export const LandingPage: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [openFaqIndex, setOpenFaqIndex] = useState<number | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const features = [
    {
      icon: <Zap className="h-8 w-8 text-red-500" />,
      title: 'Do First',
      description: 'Focus on critical tasks that need immediate attention.',
      color: 'bg-red-50 border-red-100'
    },
    {
      icon: <Target className="h-8 w-8 text-blue-500" />,
      title: 'Schedule',
      description: 'Plan important tasks to prevent future urgency.',
      color: 'bg-blue-50 border-blue-100'
    },
    {
      icon: <Clock className="h-8 w-8 text-yellow-500" />,
      title: 'Delegate',
      description: 'Manage urgent but less important tasks efficiently.',
      color: 'bg-yellow-50 border-yellow-100'
    },
    {
      icon: <Coffee className="h-8 w-8 text-gray-500" />,
      title: 'Eliminate',
      description: 'Identify and minimize non-essential activities.',
      color: 'bg-gray-50 border-gray-100'
    }
  ];

  const benefits = [
    {
      icon: <Layout className="h-12 w-12 text-purple-500" />,
      title: 'Visual Task Organization',
      description: 'Organize tasks in a clear, visual matrix for better decision making.'
    },
    {
      icon: <Calendar className="h-12 w-12 text-green-500" />,
      title: 'Time Management',
      description: 'Track and manage your time effectively with our intuitive interface.'
    },
    {
      icon: <TrendingUp className="h-12 w-12 text-blue-500" />,
      title: 'Productivity Insights',
      description: 'Gain valuable insights into your productivity patterns.'
    }
  ];

  const metrics = [
    {
      number: "10k+",
      label: "Active Users",
      icon: <Users className="h-6 w-6 text-blue-500" />
    },
    {
      number: "1M+",
      label: "Tasks Managed",
      icon: <CheckSquare className="h-6 w-6 text-green-500" />
    },
    {
      number: "50%",
      label: "Productivity Boost",
      icon: <TrendingUp className="h-6 w-6 text-purple-500" />
    },
    {
      number: "24/7",
      label: "Support",
      icon: <HeartHandshake className="h-6 w-6 text-red-500" />
    }
  ];

  const faqs = [
    {
      question: "What is the Eisenhower Matrix?",
      answer: "The Eisenhower Matrix is a productivity tool that helps you organize tasks based on their urgency and importance. It divides tasks into four quadrants: Urgent & Important, Important but Not Urgent, Urgent but Not Important, and Neither Urgent nor Important."
    },
    {
      question: "How does TimeWise help with task management?",
      answer: "TimeWise provides a visual interface for the Eisenhower Matrix, allowing you to easily categorize, track, and manage your tasks. It helps you focus on what truly matters by organizing tasks based on their priority and importance."
    },
    {
      question: "Is my data secure with TimeWise?",
      answer: "Yes, TimeWise uses industry-standard encryption and secure authentication methods to protect your data. We prioritize the security and privacy of our users' information."
    },
    {
      question: "Can I use TimeWise on mobile devices?",
      answer: "Yes, TimeWise is fully responsive and works seamlessly across all devices including smartphones and tablets. You can manage your tasks anywhere, anytime."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-purple-50 transform -skew-y-6"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-32">
          <div className="text-center">
            <h1 className="text-5xl md:text-6xl font-extrabold tracking-tight text-gray-900">
              <span className="block">Master Your Time with</span>
              <span className="block bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                TimeWise Matrix
              </span>
            </h1>
            <p className="mt-6 max-w-2xl mx-auto text-xl text-gray-500">
              Transform your productivity using the power of the Eisenhower Matrix. 
              Prioritize tasks effectively and achieve more in less time.
            </p>
            <div className="mt-10 flex justify-center gap-4">
              <Link to="/register">
                <Button size="lg" className="px-8 py-3 rounded-full">
                  Get Started Free
                </Button>
              </Link>
              <Link to="/contact">
                <Button variant="outline" size="lg" className="px-8 py-3 rounded-full">
                  Learn More
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Metrics Section - Now with reduced spacing */}
      <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Trusted by Thousands
            </h2>
            <p className="mt-2 text-xl text-gray-500">
              Join our growing community of productive professionals
            </p>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {metrics.map((metric, index) => (
              <div 
                key={index}
                className="text-center p-4 bg-gradient-to-br from-gray-50 to-gray-100 
                         rounded-xl shadow-sm hover:shadow-md transition-all duration-300"
              >
                <div className="flex justify-center mb-3">
                  <div className="p-2 bg-white rounded-lg shadow-sm">
                    {metric.icon}
                  </div>
                </div>
                <div className="text-2xl font-bold text-gray-900 mb-1">
                  {metric.number}
                </div>
                <div className="text-sm text-gray-600">
                  {metric.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Features Grid - Now with reduced top spacing */}
      <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              The Four Quadrants of Time Management
            </h2>
            <p className="mt-2 text-xl text-gray-500">
              Organize your tasks based on urgency and importance
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <div 
                key={index}
                className={`${feature.color} border rounded-2xl p-8 transition-all duration-300 hover:shadow-lg`}
              >
                <div className="bg-white rounded-xl p-3 shadow-sm inline-block">
                  {feature.icon}
                </div>
                <h3 className="mt-6 text-xl font-semibold text-gray-900">
                  {feature.title}
                </h3>
                <p className="mt-4 text-gray-600">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="py-24 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Why Choose TimeWise?
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {benefits.map((benefit, index) => (
              <div 
                key={index}
                className="text-center"
              >
                <div className="flex justify-center mb-6">
                  <div className="p-4 bg-white rounded-2xl shadow-md">
                    {benefit.icon}
                  </div>
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  {benefit.title}
                </h3>
                <p className="text-gray-600">
                  {benefit.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQ Section with neutral colors */}
      <div className="py-16 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Frequently Asked Questions
            </h2>
          </div>

          <div className="space-y-2">
            {faqs.map((faq, index) => (
              <div 
                key={index}
                className="border border-gray-200 rounded-lg overflow-hidden"
              >
                <button
                  onClick={() => setOpenFaqIndex(openFaqIndex === index ? null : index)}
                  className="w-full px-6 py-4 text-left flex items-center justify-between 
                           bg-white hover:bg-gray-50 transition-colors"
                >
                  <span className="font-medium text-gray-900">{faq.question}</span>
                  <span className="ml-6">
                    <Plus 
                      className={`w-4 h-4 text-gray-400 transform transition-transform ${
                        openFaqIndex === index ? 'rotate-45' : ''
                      }`}
                    />
                  </span>
                </button>
                {openFaqIndex === index && (
                  <div className="px-6 py-4 bg-gray-50 text-gray-600 border-t border-gray-200">
                    {faq.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600">
        <div className="max-w-4xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            Ready to Transform Your Productivity?
          </h2>
          <p className="mt-4 text-lg leading-6 text-blue-100">
            Join thousands of users who have improved their time management with TimeWise.
          </p>
          <Link to="/register">
            <Button 
              variant="secondary" 
              size="lg" 
              className="mt-8 w-full sm:w-auto font-bold px-8 py-3 rounded-full"
            >
              Start Your Free Account
            </Button>
          </Link>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center space-x-2">
              <Clock className="h-8 w-8 text-white" />
              <span className="text-xl font-bold text-white">TimeWise</span>
            </div>
            <div className="mt-4 md:mt-0 flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-6">
              <Link to="/contact" className="text-gray-300 hover:text-white transition-colors">
                Contact Us
              </Link>
              <p className="text-gray-400">
                Created by{' '}
                <a 
                  href="https://x.com/patilketan671" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-300 transition-colors"
                >
                  Ketan
                </a>
              </p>
              <p className="text-gray-400">© 2024 TimeWise</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}; 