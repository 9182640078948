import React from 'react';
import { Zap, Target, Clock, Coffee } from 'lucide-react';
import { Task } from '../../types';

interface TaskStatsProps {
  tasks: Task[];
}

export const TaskStats: React.FC<TaskStatsProps> = ({ tasks }) => {
  const calculateStats = () => {
    return {
      total: tasks.length,
      doFirst: tasks.filter(t => t.urgency === 'Yes' && t.importance === 'Yes').length,
      schedule: tasks.filter(t => t.urgency === 'No' && t.importance === 'Yes').length,
      delegate: tasks.filter(t => t.urgency === 'Yes' && t.importance === 'No').length,
      dontDo: tasks.filter(t => t.urgency === 'No' && t.importance === 'No').length
    };
  };

  const stats = calculateStats();

  return (
    <div className="grid grid-cols-5 gap-4">
      {/* Total Tasks */}
      <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-2xl p-4 transition-all duration-300 hover:shadow-md">
        <div className="flex items-center justify-between mb-3">
          <span className="text-sm font-medium text-gray-600">Total Tasks</span>
          <div className="bg-white p-2 rounded-lg">
            <Clock className="w-5 h-5 text-blue-600" />
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-2xl font-bold text-gray-900">{stats.total}</span>
          <span className="text-xs text-gray-500 mt-1">All tasks</span>
        </div>
      </div>

      {/* Do First */}
      <div className="bg-gradient-to-br from-red-50 to-red-100 rounded-2xl p-4 transition-all duration-300 hover:shadow-md">
        <div className="flex items-center justify-between mb-3">
          <span className="text-sm font-medium text-gray-600">Do First</span>
          <div className="bg-white p-2 rounded-lg">
            <Zap className="w-5 h-5 text-red-600" />
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-2xl font-bold text-gray-900">{stats.doFirst}</span>
          <span className="text-xs text-gray-500 mt-1">Urgent & Important</span>
        </div>
      </div>

      {/* Schedule */}
      <div className="bg-gradient-to-br from-indigo-50 to-indigo-100 rounded-2xl p-4 transition-all duration-300 hover:shadow-md">
        <div className="flex items-center justify-between mb-3">
          <span className="text-sm font-medium text-gray-600">Schedule</span>
          <div className="bg-white p-2 rounded-lg">
            <Target className="w-5 h-5 text-indigo-600" />
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-2xl font-bold text-gray-900">{stats.schedule}</span>
          <span className="text-xs text-gray-500 mt-1">Important, Not Urgent</span>
        </div>
      </div>

      {/* Delegate */}
      <div className="bg-gradient-to-br from-yellow-50 to-yellow-100 rounded-2xl p-4 transition-all duration-300 hover:shadow-md">
        <div className="flex items-center justify-between mb-3">
          <span className="text-sm font-medium text-gray-600">Delegate</span>
          <div className="bg-white p-2 rounded-lg">
            <Clock className="w-5 h-5 text-yellow-600" />
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-2xl font-bold text-gray-900">{stats.delegate}</span>
          <span className="text-xs text-gray-500 mt-1">Urgent, Not Important</span>
        </div>
      </div>

      {/* Don't Do */}
      <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-2xl p-4 transition-all duration-300 hover:shadow-md">
        <div className="flex items-center justify-between mb-3">
          <span className="text-sm font-medium text-gray-600">Don't Do</span>
          <div className="bg-white p-2 rounded-lg">
            <Coffee className="w-5 h-5 text-gray-600" />
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-2xl font-bold text-gray-900">{stats.dontDo}</span>
          <span className="text-xs text-gray-500 mt-1">Not Urgent, Not Important</span>
        </div>
      </div>
    </div>
  );
}; 