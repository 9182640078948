import React, { useState } from 'react';
import { Clock, Zap, Target, Coffee, Trash2, Edit2, X, Check, AlertCircle, Info } from 'lucide-react';
import { Task } from '../../types';
import { supabase } from '../../lib/supabase';
import { Button } from '../ui/Button';
import { CountdownTimer } from './CountdownTimer';

interface TaskMatrixProps {
  tasks: Task[];
  onTaskClick: (task: Task) => void;
  onTaskDeleted: () => void;
  onTaskUpdated: () => void;
}

interface QuadrantCardProps {
  title: string;
  tasks: Task[];
  icon: React.ElementType;
  bgColor: string;
  onTaskClick: (task: Task) => void;
}

export const TaskMatrix: React.FC<TaskMatrixProps> = ({ tasks, onTaskClick, onTaskDeleted, onTaskUpdated }) => {
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const [editedName, setEditedName] = useState('');
  const [editedTime, setEditedTime] = useState('');
  const [editedUrgency, setEditedUrgency] = useState('No');
  const [editedImportance, setEditedImportance] = useState('No');
  const [editedTimeTaken, setEditedTimeTaken] = useState('No');
  const [editedTargetDate, setEditedTargetDate] = useState('');
  const [editedTargetTime, setEditedTargetTime] = useState('');
  const [error, setError] = useState('');

  const handleEditClick = (task: Task, e: React.MouseEvent) => {
    e.stopPropagation();
    setEditingTask(task);
    setEditedName(task.task);
    setEditedTime(task.task_time.toString());
    setEditedUrgency(task.urgency);
    setEditedImportance(task.importance);
    setEditedTimeTaken(task.time_taken);
    if (task.target_date) {
      const date = new Date(task.target_date);
      setEditedTargetDate(date.toISOString().split('T')[0]);
      setEditedTargetTime(date.toTimeString().slice(0, 5));
    }
    setError('');
  };

  const handleSaveEdit = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!editedName.trim() || !editedTime) {
      setError('Please fill in all required fields');
      return;
    }

    try {
      const targetDateTime = editedTargetDate && editedTargetTime
        ? new Date(`${editedTargetDate}T${editedTargetTime}`)
        : null;

      const { error: updateError } = await supabase
        .from('tasks')
        .update({
          task: editedName.trim(),
          task_time: parseInt(editedTime),
          urgency: editedUrgency,
          importance: editedImportance,
          time_taken: editedTimeTaken,
          target_date: targetDateTime?.toISOString() || null,
          updated_at: new Date().toISOString()
        })
        .eq('id', editingTask?.id);

      if (updateError) throw updateError;

      setEditingTask(null);
      onTaskUpdated();
    } catch (error) {
      console.error('Error updating task:', error);
      setError('Failed to update task');
    }
  };

  const handleDeleteTask = async (taskId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const { error } = await supabase
        .from('tasks')
        .delete()
        .eq('id', taskId);

      if (error) throw error;
      onTaskDeleted();
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const renderTask = (task: Task) => (
    <div
      key={task.id}
      className="bg-white rounded-lg p-3 sm:p-4 shadow-sm hover:shadow-md transition-all duration-200 cursor-pointer"
      onClick={() => onTaskClick(task)}
    >
      {editingTask?.id === task.id ? (
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Task Name
            </label>
            <input
              type="text"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              autoFocus
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Time (minutes)
            </label>
            <input
              type="number"
              value={editedTime}
              onChange={(e) => setEditedTime(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              min="1"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Target Date
              </label>
              <input
                type="date"
                value={editedTargetDate}
                onChange={(e) => setEditedTargetDate(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                min={new Date().toISOString().split('T')[0]}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                Target Time
                <div className="relative group ml-1">
                  <Info className="w-4 h-4 text-gray-400 cursor-help" />
                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                    <div className="text-sm">
                      <p className="mb-1">Set the target completion time for your task.</p>
                      <p className="mb-1">• Format: HH:MM (24-hour)</p>
                      <p className="mb-1">• Example: 14:30 for 2:30 PM</p>
                      <p>• The countdown timer will show time remaining until this target.</p>
                    </div>
                    <div className="absolute top-full left-1/2 transform -translate-x-1/2 -mt-1">
                      <div className="border-8 border-transparent border-t-gray-800"></div>
                    </div>
                  </div>
                </div>
              </label>
              <input
                type="time"
                value={editedTargetTime}
                onChange={(e) => setEditedTargetTime(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Urgency
              </label>
              <select
                value={editedUrgency}
                onChange={(e) => setEditedUrgency(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Importance
              </label>
              <select
                value={editedImportance}
                onChange={(e) => setEditedImportance(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Time Taken
              </label>
              <select
                value={editedTimeTaken}
                onChange={(e) => setEditedTimeTaken(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
          </div>

          {error && (
            <div className="text-red-500 text-sm flex items-center">
              <AlertCircle className="w-4 h-4 mr-1" />
              {error}
            </div>
          )}

          <div className="flex justify-end space-x-2">
            <Button
              size="sm"
              variant="outline"
              onClick={(e) => {
                e.stopPropagation();
                setEditingTask(null);
                setError('');
              }}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              onClick={handleSaveEdit}
            >
              Save Changes
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex justify-between items-start">
          <div>
            <h4 className="font-medium text-gray-900">{task.task}</h4>
            <div className="flex flex-wrap items-center mt-2 gap-2">
              <span className="flex items-center text-sm text-gray-500">
                <Clock className="w-4 h-4 mr-1" />
                {task.task_time} min
              </span>
              {task.target_date && (
                <CountdownTimer targetDate={task.target_date} />
              )}
              {task.time_taken === 'Yes' && (
                <span className="px-2 py-0.5 bg-green-100 text-green-800 rounded-full text-xs font-medium">
                  Time Taken
                </span>
              )}
            </div>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={(e) => handleEditClick(task, e)}
              className="p-1 text-gray-400 hover:text-blue-500 transition-colors rounded-full hover:bg-blue-50"
            >
              <Edit2 className="w-4 h-4" />
            </button>
            <button
              onClick={(e) => handleDeleteTask(task.id, e)}
              className="p-1 text-gray-400 hover:text-red-500 transition-colors rounded-full hover:bg-red-50"
            >
              <Trash2 className="w-4 h-4" />
            </button>
          </div>
        </div>
      )}
    </div>
  );

  const QuadrantCard = ({ title, tasks, icon: Icon, bgColor }: QuadrantCardProps) => (
    <div className={`p-6 rounded-xl ${bgColor} h-full`}>
      <div className="flex items-center mb-4">
        <Icon className="w-5 h-5 mr-2" />
        <h3 className="text-lg font-semibold">{title}</h3>
      </div>

      <div className="space-y-3">
        {tasks.length === 0 ? (
          <div className="text-gray-500 text-sm">No tasks in this quadrant</div>
        ) : (
          tasks.map(task => renderTask(task))
        )}
      </div>
    </div>
  );

  const urgentImportant = tasks.filter(t => t.urgency === 'Yes' && t.importance === 'Yes');
  const importantNotUrgent = tasks.filter(t => t.urgency === 'No' && t.importance === 'Yes');
  const urgentNotImportant = tasks.filter(t => t.urgency === 'Yes' && t.importance === 'No');
  const notUrgentNotImportant = tasks.filter(t => t.urgency === 'No' && t.importance === 'No');

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <QuadrantCard
        title="Urgent & Important"
        tasks={urgentImportant}
        icon={Zap}
        bgColor="bg-red-50"
        onTaskClick={onTaskClick}
      />
      <QuadrantCard
        title="Important, Not Urgent"
        tasks={importantNotUrgent}
        icon={Target}
        bgColor="bg-blue-50"
        onTaskClick={onTaskClick}
      />
      <QuadrantCard
        title="Urgent, Not Important"
        tasks={urgentNotImportant}
        icon={Clock}
        bgColor="bg-yellow-50"
        onTaskClick={onTaskClick}
      />
      <QuadrantCard
        title="Not Urgent, Not Important"
        tasks={notUrgentNotImportant}
        icon={Coffee}
        bgColor="bg-green-50"
        onTaskClick={onTaskClick}
      />
    </div>
  );
}; 