import React, { useState, useEffect } from 'react';
import { supabase } from '../../lib/supabase';
import { AdminStats, UserMetrics, TaskMetrics, AdminDashboardData } from '../../types/admin';
import { Users, CheckSquare, Clock, Activity, Info } from 'lucide-react';

// Add a StatsCard component for reusability
interface StatsCardProps {
  icon: React.ReactNode;
  title: string;
  value: number | string;
  color: string;
  info?: string;
}

const StatsCard: React.FC<StatsCardProps> = ({ icon, title, value, color, info }) => (
  <div className="bg-white rounded-lg shadow p-6">
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <div className={`${color}`}>
          {icon}
        </div>
        <div className="ml-4">
          <div className="flex items-center">
            <h3 className="text-lg font-semibold text-gray-600">{title}</h3>
            {info && (
              <div className="relative group ml-2">
                <Info className="w-4 h-4 text-gray-400 cursor-help" />
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                  <p className="text-sm">{info}</p>
                  <div className="absolute top-full left-1/2 transform -translate-x-1/2 -mt-1">
                    <div className="border-8 border-transparent border-t-gray-800"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <p className="text-2xl font-bold">{value}</p>
        </div>
      </div>
    </div>
  </div>
);

// Update MetricRow interface to handle both number and string values
interface MetricRowProps {
  label: string;
  value: number | string;
  info?: string;
}

const MetricRow: React.FC<MetricRowProps> = ({ label, value, info }) => (
  <div className="flex justify-between items-center">
    <div className="flex items-center">
      <span className="text-gray-600">{label}</span>
      {info && (
        <div className="relative group ml-2">
          <Info className="w-4 h-4 text-gray-400 cursor-help" />
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
            <p className="text-sm">{info}</p>
            <div className="absolute top-full left-1/2 transform -translate-x-1/2 -mt-1">
              <div className="border-8 border-transparent border-t-gray-800"></div>
            </div>
          </div>
        </div>
      )}
    </div>
    <span className="font-semibold">{value}</span>
  </div>
);

// Add UserTasksTable component
interface UserTasksTableProps {
  userMetrics: Array<{
    id: string;
    email: string;
    tasksCreated: number;
    tasksCompleted: number;
    lastActive: string;
    joinDate: string;
  }>;
}

const UserTasksTable: React.FC<UserTasksTableProps> = ({ userMetrics }) => {
  // Sort userMetrics by tasksCreated in descending order
  const sortedMetrics = [...userMetrics].sort((a, b) => b.tasksCreated - a.tasksCreated);

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-900">User-wise Task Distribution</h3>
        <div className="relative group ml-2">
          <Info className="w-4 h-4 text-gray-400 cursor-help" />
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
            <p className="text-sm">Detailed breakdown of tasks per user, showing their activity and completion rates. Sorted by most tasks created.</p>
          </div>
        </div>
      </div>
      
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                User Email
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider flex items-center">
                Tasks Created
                <span className="ml-1 text-blue-500">↓</span>
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Tasks Completed
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Completion Rate
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Last Active
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedMetrics.map((user) => (
              <tr key={user.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {user.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-blue-600">
                  {user.tasksCreated}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {user.tasksCompleted}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {user.tasksCreated > 0 
                    ? `${Math.round((user.tasksCompleted / user.tasksCreated) * 100)}%`
                    : '0%'
                  }
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {new Date(user.lastActive).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const AdminDashboard: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<AdminDashboardData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchAdminData();
  }, []);

  const fetchAdminData = async () => {
    try {
      setIsLoading(true);
      setError(null);

      console.log('Fetching admin analytics data...');
      
      // Fetch analytics data from the view
      const { data: analyticsData, error: analyticsError } = await supabase
        .from('admin_analytics_timewise')
        .select('*');

      console.log('Analytics data:', analyticsData, 'Error:', analyticsError);

      if (analyticsError) {
        console.error('Analytics error:', analyticsError);
        throw new Error('Failed to fetch analytics data');
      }

      // Get total users from the first row of analytics data
      const totalUsers = analyticsData?.[0]?.total_users || 0;

      // Initialize with empty data if no analytics available
      const aggregatedMetrics = analyticsData?.reduce((acc: any, curr: any) => ({
        totalTasks: (acc.totalTasks || 0) + (curr.tasks_created || 0),
        completedTasks: (acc.completedTasks || 0) + (curr.tasks_completed || 0),
        urgentImportant: (acc.urgentImportant || 0) + (curr.urgent_important_tasks || 0),
        importantNotUrgent: (acc.importantNotUrgent || 0) + (curr.important_not_urgent_tasks || 0),
        urgentNotImportant: (acc.urgentNotImportant || 0) + (curr.urgent_not_important_tasks || 0),
        notUrgentNotImportant: (acc.notUrgentNotImportant || 0) + (curr.not_urgent_not_important_tasks || 0),
        averageTaskTime: [...(acc.averageTaskTime || []), curr.average_task_time],
      }), {
        totalTasks: 0,
        completedTasks: 0,
        urgentImportant: 0,
        importantNotUrgent: 0,
        urgentNotImportant: 0,
        notUrgentNotImportant: 0,
        averageTaskTime: [] as number[],
      }) || {
        totalTasks: 0,
        completedTasks: 0,
        urgentImportant: 0,
        importantNotUrgent: 0,
        urgentNotImportant: 0,
        notUrgentNotImportant: 0,
        averageTaskTime: [] as number[],
      };

      // Calculate overall average task time
      const overallAverageTaskTime = aggregatedMetrics.averageTaskTime.length > 0
        ? aggregatedMetrics.averageTaskTime.reduce((a: number, b: number) => a + (b || 0), 0) / aggregatedMetrics.averageTaskTime.length
        : 0;

      // Set the dashboard data
      setData({
        stats: {
          totalUsers,
          totalTasks: aggregatedMetrics.totalTasks || 0,
          activeUsers: analyticsData?.length || 0,
          completedTasks: aggregatedMetrics.completedTasks || 0,
          tasksByQuadrant: {
            urgentImportant: aggregatedMetrics.urgentImportant || 0,
            importantNotUrgent: aggregatedMetrics.importantNotUrgent || 0,
            urgentNotImportant: aggregatedMetrics.urgentNotImportant || 0,
            notUrgentNotImportant: aggregatedMetrics.notUrgentNotImportant || 0,
          },
        },
        taskMetrics: {
          totalTasks: aggregatedMetrics.totalTasks || 0,
          completedTasks: aggregatedMetrics.completedTasks || 0,
          averageTaskTime: overallAverageTaskTime || 0,
          tasksByUrgency: {
            urgent: (aggregatedMetrics.urgentImportant || 0) + (aggregatedMetrics.urgentNotImportant || 0),
            notUrgent: (aggregatedMetrics.importantNotUrgent || 0) + (aggregatedMetrics.notUrgentNotImportant || 0),
          },
          tasksByImportance: {
            important: (aggregatedMetrics.urgentImportant || 0) + (aggregatedMetrics.importantNotUrgent || 0),
            notImportant: (aggregatedMetrics.urgentNotImportant || 0) + (aggregatedMetrics.notUrgentNotImportant || 0),
          },
          tasksByTimeOfDay: {
            morning: 0,
            afternoon: 0,
            evening: 0,
            night: 0,
          },
        },
        userMetrics: analyticsData?.map(user => ({
          id: user.user_id || '',
          email: user.user_email || '',
          tasksCreated: user.tasks_created || 0,
          tasksCompleted: user.tasks_completed || 0,
          lastActive: user.last_active || '',
          joinDate: user.first_task_date || '',
        })) || [],
        recentActivity: [],
      });

    } catch (err) {
      console.error('Error fetching admin data:', err);
      setError(err instanceof Error ? err.message : 'Failed to load admin dashboard data');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 p-6">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="min-h-screen bg-gray-50 p-6">
        <div className="bg-yellow-50 border border-yellow-200 text-yellow-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">No Data: </strong>
          <span className="block sm:inline">No analytics data available.</span>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <h1 className="text-2xl font-bold text-gray-900 mb-8">Admin Dashboard</h1>

      {/* Quick Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatsCard
          icon={<Users className="w-12 h-12" />}
          title="Total Users"
          value={data.stats.totalUsers}
          color="text-blue-500"
          info="Total number of unique users who have created at least one task"
        />

        <StatsCard
          icon={<Activity className="w-12 h-12" />}
          title="Active Users"
          value={data.stats.activeUsers}
          color="text-purple-500"
          info="Number of users who have created or updated tasks in the last 24 hours"
        />

        <StatsCard
          icon={<CheckSquare className="w-12 h-12" />}
          title="Completed Tasks"
          value={data.stats.completedTasks}
          color="text-green-500"
          info="Total number of tasks marked as completed (Time Taken = Yes)"
        />

        <StatsCard
          icon={<Clock className="w-12 h-12" />}
          title="Avg Task Time"
          value={`${Math.round(data.taskMetrics.averageTaskTime)} min`}
          color="text-yellow-500"
          info="Average time allocated for tasks across all users"
        />
      </div>

      {/* Task Distribution */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-900">Tasks by Quadrant</h3>
            <div className="relative group ml-2">
              <Info className="w-4 h-4 text-gray-400 cursor-help" />
              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block w-72 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                <p className="text-sm mb-2">Tasks are categorized into four quadrants based on their urgency and importance:</p>
                <ul className="list-disc pl-4 space-y-1">
                  <li>Urgent & Important: Critical tasks requiring immediate attention</li>
                  <li>Important, Not Urgent: Strategic tasks for long-term goals</li>
                  <li>Urgent, Not Important: Time-sensitive but less crucial tasks</li>
                  <li>Not Urgent, Not Important: Optional or low-priority tasks</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <MetricRow 
              label="Urgent & Important" 
              value={data.stats.tasksByQuadrant.urgentImportant}
              info="Tasks that are both urgent and important - require immediate attention and have significant impact"
            />
            <MetricRow 
              label="Important, Not Urgent" 
              value={data.stats.tasksByQuadrant.importantNotUrgent}
              info="Tasks that are important but not time-critical - focus on long-term goals and planning"
            />
            <MetricRow 
              label="Urgent, Not Important" 
              value={data.stats.tasksByQuadrant.urgentNotImportant}
              info="Tasks that are time-sensitive but have less impact - could potentially be delegated"
            />
            <MetricRow 
              label="Not Urgent, Not Important" 
              value={data.stats.tasksByQuadrant.notUrgentNotImportant}
              info="Tasks with low priority and impact - consider eliminating or minimizing these"
            />
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-900">Task Completion Rate</h3>
            <div className="relative group ml-2">
              <Info className="w-4 h-4 text-gray-400 cursor-help" />
              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                <p className="text-sm mb-2">Task completion metrics show the overall productivity and task management efficiency:</p>
                <ul className="list-disc pl-4 space-y-1">
                  <li>Total Tasks: All tasks created in the system</li>
                  <li>Completed Tasks: Tasks marked as "Time Taken"</li>
                  <li>Completion Rate: Percentage of completed vs total tasks</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <MetricRow 
              label="Total Tasks" 
              value={data.taskMetrics.totalTasks}
              info="Total number of tasks created across all users"
            />
            <MetricRow 
              label="Completed Tasks" 
              value={data.taskMetrics.completedTasks}
              info="Number of tasks marked as completed (Time Taken = Yes)"
            />
            <MetricRow 
              label="Completion Rate" 
              value={`${Math.round((data.taskMetrics.completedTasks / data.taskMetrics.totalTasks) * 100)}%`}
              info="Percentage of tasks that have been completed out of all tasks"
            />
          </div>
        </div>
      </div>

      {/* User Tasks Table */}
      <div className="mt-8">
        <UserTasksTable userMetrics={data.userMetrics} />
      </div>

      {/* More sections will be added in Phase 2 */}
    </div>
  );
}; 