import React, { useState, useEffect, useRef } from 'react';
import { Plus, Check, Info } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useAuthStore } from '../../store/authStore';

interface TaskFormProps {
  onTaskAdded: () => void;
}

export const TaskForm: React.FC<TaskFormProps> = ({ onTaskAdded }) => {
  const [formData, setFormData] = useState({
    task: '',
    taskTime: '',
    urgency: 'No',
    importance: 'No',
    timeTaken: 'No',
    targetDate: '',
    targetTime: ''
  });
  
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState('');
  const user = useAuthStore(state => state.user);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    
    if (!formData.task.trim() || !formData.taskTime || !formData.targetDate || !formData.targetTime) {
      setError('Please fill in all required fields');
      return;
    }

    setIsLoading(true);
    try {
      const targetDateTime = new Date(`${formData.targetDate}T${formData.targetTime}`);

      const { error: supabaseError } = await supabase.from('tasks').insert([{
        user_id: user?.id,
        user_email: user?.email,
        user_name: user?.email?.split('@')[0],
        task: formData.task.trim(),
        task_time: parseInt(formData.taskTime),
        urgency: formData.urgency,
        importance: formData.importance,
        time_taken: formData.timeTaken,
        target_date: targetDateTime.toISOString(),
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }]);

      if (supabaseError) {
        console.error('Supabase error:', supabaseError);
        throw supabaseError;
      }

      // Show success message
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);

      // Reset form
      setFormData({
        task: '',
        taskTime: '',
        urgency: 'No',
        importance: 'No',
        timeTaken: 'No',
        targetDate: '',
        targetTime: ''
      });
      
      // Notify parent
      onTaskAdded();
      
      // Focus back on input
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } catch (err) {
      console.error('Error adding task:', err);
      setError('Error creating task. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-900">Add New Task</h2>
        {showSuccess && (
          <div className="flex items-center text-green-600 bg-green-50 px-3 py-1 rounded-full">
            <Check className="w-4 h-4 mr-2" />
            <span className="text-sm">Task added successfully!</span>
          </div>
        )}
      </div>
      
      <form onSubmit={handleSubmit} className="space-y-4">
        {error && (
          <div className="text-red-500 text-sm">{error}</div>
        )}
        
        {/* Task Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Task
          </label>
          <input
            ref={inputRef}
            type="text"
            name="task"
            value={formData.task}
            onChange={handleChange}
            placeholder="Enter task description"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            required
          />
        </div>

        {/* Time Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Time (minutes)
          </label>
          <input
            type="number"
            name="taskTime"
            value={formData.taskTime}
            onChange={handleChange}
            placeholder="Enter time in minutes"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            required
            min="1"
          />
        </div>

        {/* Target Date and Time */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Target Date
            </label>
            <input
              type="date"
              name="targetDate"
              value={formData.targetDate}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              min={new Date().toISOString().split('T')[0]}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
              Target Time
              <div className="relative group ml-1">
                <Info className="w-4 h-4 text-gray-400 cursor-help" />
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg">
                  <div className="text-sm">
                    <p className="mb-1">Set the target completion time for your task.</p>
                    <p className="mb-1">• Format: HH:MM (24-hour)</p>
                    <p className="mb-1">• Example: 14:30 for 2:30 PM</p>
                    <p>• The countdown timer will show time remaining until this target.</p>
                  </div>
                  <div className="absolute top-full left-1/2 transform -translate-x-1/2 -mt-1">
                    <div className="border-8 border-transparent border-t-gray-800"></div>
                  </div>
                </div>
              </div>
            </label>
            <input
              type="time"
              name="targetTime"
              value={formData.targetTime}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        {/* Dropdowns Row */}
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Urgency
            </label>
            <select
              name="urgency"
              value={formData.urgency}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Importance
            </label>
            <select
              name="importance"
              value={formData.importance}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Time Taken
            </label>
            <select
              name="timeTaken"
              value={formData.timeTaken}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isLoading}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 
                     focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                     disabled:opacity-50 disabled:cursor-not-allowed
                     flex items-center space-x-2"
          >
            {isLoading ? (
              <>
                <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                Adding...
              </>
            ) : (
              <>
                <Plus className="w-5 h-5" />
                <span>Add Task</span>
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}; 