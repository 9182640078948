import React, { useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useAuthStore } from '../store/authStore';
import { Session } from '@supabase/supabase-js';

export const SessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const setUser = useAuthStore(state => state.setUser);

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        setUser({
          id: session.user.id,
          email: session.user.email!,
        });
      }
    });

    // Listen for auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        setUser({
          id: session.user.id,
          email: session.user.email!,
        });
      } else {
        setUser(null);
      }
    });

    // Cleanup subscription
    return () => subscription.unsubscribe();
  }, [setUser]);

  return <>{children}</>;
}; 