import React, { useState, useEffect } from 'react';
import { Clock } from 'lucide-react';

interface CountdownTimerProps {
  targetDate: string;
}

export const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate }) => {
  console.log('CountdownTimer rendered with targetDate:', targetDate);

  const [timeLeft, setTimeLeft] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const calculateTimeLeft = () => {
      try {
        const targetTime = new Date(targetDate).getTime();
        const now = new Date().getTime();
        const difference = targetTime - now;
        
        console.log('Calculating time left:', {
          targetDate,
          targetTime,
          now,
          difference
        });
        
        if (difference <= 0) {
          console.log('Target date has expired');
          setIsExpired(true);
          return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        const timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        };

        console.log('Calculated time left:', timeLeft);
        return timeLeft;
      } catch (error) {
        console.error('Error calculating time left:', error);
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
      }
    };

    // Calculate immediately
    const initialTimeLeft = calculateTimeLeft();
    console.log('Initial time left:', initialTimeLeft);
    setTimeLeft(initialTimeLeft);
    setIsExpired(initialTimeLeft.days === 0 && 
                initialTimeLeft.hours === 0 && 
                initialTimeLeft.minutes === 0 && 
                initialTimeLeft.seconds === 0);

    // Then update every second
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
      setIsExpired(newTimeLeft.days === 0 && 
                  newTimeLeft.hours === 0 && 
                  newTimeLeft.minutes === 0 && 
                  newTimeLeft.seconds === 0);
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  if (isExpired) {
    return (
      <div className="flex items-center text-red-500 text-sm font-medium">
        <Clock className="w-4 h-4 mr-1" />
        Expired
      </div>
    );
  }

  return (
    <div className="flex items-center text-blue-600 text-sm font-medium">
      <Clock className="w-4 h-4 mr-1" />
      {timeLeft.days > 0 && `${timeLeft.days}d `}
      {String(timeLeft.hours).padStart(2, '0')}:
      {String(timeLeft.minutes).padStart(2, '0')}:
      {String(timeLeft.seconds).padStart(2, '0')}
    </div>
  );
}; 